import moment from 'moment';

type ShowClockOutPastScheduleProps = {
  now: string | Date;
  expectedEndTime: string | Date;
  disabledEditClockOutTime: boolean;
};

const showClockOutPastSchedule = ({
  now,
  expectedEndTime,
  disabledEditClockOutTime,
}: ShowClockOutPastScheduleProps) => {
  const fiveMinutesGraced = moment(expectedEndTime).add(5, 'minutes');
  const sixMinutesFromEndTime = moment(expectedEndTime).add(6, 'minutes');
  const currentTime = moment(now);

  if (disabledEditClockOutTime) {
    return expectedEndTime;
  }

  const isWithinGracePeriod =
    fiveMinutesGraced.isSameOrAfter(currentTime) &&
    currentTime.isBefore(sixMinutesFromEndTime) &&
    currentTime.isAfter(moment(expectedEndTime));

  return isWithinGracePeriod ? expectedEndTime : now;
};

export const ClockInOutDisplayHelpers = {
  showClockOutPastSchedule,
};
