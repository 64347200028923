import { Box, Button, IconButton } from '@medely/web-components';
import { InfoBadge, useFirebaseContext } from '@medely/ui-kit';
import { Xmark } from '@medely/web-components/icons';
import useCurrentUser from 'hooks/useCurrentUser';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import React, { useState } from 'react';
import { isProduction } from 'utils/env';

const Unimpersonate = (): React.ReactElement | null => {
  const [show, setShow] = useState(true);
  const { logout } = useFirebaseContext();
  const impersonating = window.localStorage.getItem('impersonating') === 'true';
  const { currentUser } = useCurrentUser();
  const handleStop = () => logout();
  const isStaging = !isProduction();

  const { width } = useWindowDimensions();
  const isAtMinWidth = width < 600;

  const stopButton = (
    <Button
      sx={{
        color: 'white',
        borderColor: 'white',
        marginLeft: '8px',
        width: '80px',
        height: '24px',
        fontSize: '12px',
        fontWeight: 'medium',
        paddingLeft: '8px',
      }}
      variant="outlined"
      onClick={handleStop}
    >
      Stop
    </Button>
  );

  return impersonating && show ? (
    <Box height={42}>
      <Box
        bgcolor={isStaging ? '#015582' : '#3E3E83'}
        color="white"
        height={42}
        width="100%"
        sx={{
          zIndex: 100,
          position: 'fixed',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '-8px -8px 0 -8px',
          fontSize: '13px',
          fontWeight: 'medium',
        }}
      >
        <IconButton sx={{ float: 'left', paddingRight: '12px' }} onClick={() => setShow(false)}>
          <Xmark htmlColor="white" />
        </IconButton>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ paddingRight: '1rem' }}>
            {!isStaging && <InfoBadge label="PROD" color="default" />}
          </Box>
          <Box>
            Impersonating {`${currentUser.first_name} ${currentUser.last_name} (${currentUser.id})`}
            {!isAtMinWidth && stopButton}
          </Box>
        </Box>
        {isAtMinWidth ? stopButton : <div />}
      </Box>
    </Box>
  ) : null;
};

export default Unimpersonate;
