import { Box, IconButton } from '@medely/web-components';
import { Chat } from '@medely/web-components/icons';
import HideFrom from '@src/components/HideFrom';
import React from 'react';
import show from 'utils/Intercom/show';

const ChatMenu = () => {
  const handleIconClick = () => {
    show();
  };
  return (
    <HideFrom saas>
      <IconButton onClick={handleIconClick} sx={{ marginRight: 1 }}>
        <Box display="inline-block" position="relative" mx="auto" data-testid="chat">
          <Chat color="textPrimary" />
        </Box>
      </IconButton>
    </HideFrom>
  );
};

export default ChatMenu;
