import { getApp, getApps, initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { defaultConfig } from '@medely/base';

//https://github.com/firebase/firebase-js-sdk/issues/7888#issuecomment-2114939292
const app =
  getApps().length === 0
    ? initializeApp({ ...defaultConfig.firebaseConfig, appId: 'medely-app' }, 'medely-app')
    : getApp();
const auth = getAuth(app);
export { auth, signInWithEmailAndPassword };
