import React, { PropsWithChildren, ReactElement } from 'react';
import useCurrentUser from 'hooks/useCurrentUser';
import {
  isSaasCredentialingAndSchedulingAccount,
  isSaasCredentialingAccount,
  isAgencyAccount,
} from 'utils/account';

type SaasType = 'credentialing' | 'scheduling';

type HideFromProps = PropsWithChildren<{
  saas?: boolean;
  agency?: boolean;
  saasTypes?: SaasType[];
  fallback?: ReactElement;
}>;

const HideFrom = ({
  saas,
  agency,
  saasTypes = ['credentialing', 'scheduling'],
  children,
  fallback,
}: HideFromProps) => {
  const { currentUser } = useCurrentUser();

  const isCredentialingAndScheduling = isSaasCredentialingAndSchedulingAccount(currentUser);
  const isCredentialing = isSaasCredentialingAccount(currentUser);
  const isAgency = isAgencyAccount(currentUser);

  if (saas) {
    if (isCredentialingAndScheduling || isCredentialing) {
      const saasType: SaasType = isCredentialingAndScheduling ? 'scheduling' : 'credentialing';
      if (saasTypes.includes(saasType)) {
        return fallback ?? null;
      }
    }
  }

  if (agency) {
    if (isAgency) {
      return fallback ?? null;
    }
  }

  return <>{children}</>;
};

export default HideFrom;
