import { Box, HStack, Icon, Text } from '@medely/ui-kit';
import React from 'react';

type ChangeEndTimeWarningProps = {
  smallIcon?: boolean;
};

export const ChangeEndTimeWarning = ({ smallIcon = false }: ChangeEndTimeWarningProps) => {
  return (
    <HStack alignContent="center" pl={0.5} pt={1}>
      <Icon
        name="info-circle"
        iconSize={smallIcon ? '13px' : '19px'}
        color="state.error.secondary"
        variant="custom"
      />
      <Box pl={2}>
        <Text size="l" color="text.secondary">
          Clocking out past scheduled end time may cause payout delays.
        </Text>
      </Box>
    </HStack>
  );
};
